import React, { Fragment, useEffect, useRef, useState } from 'react'
import { Transition, Menu, Disclosure } from '@headlessui/react'
import { useGlobalState } from '@/state'
import SVGIcon from '@/shared/icon'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import classNames from '@/utils/classNamesLocal'
import { ThemeToggle } from '@/shared/ThemeToggle'

import {
  AcademicCapIcon,
  AdjustmentsIcon,
  AnnotationIcon,
  BookOpenIcon,
  CalculatorIcon,
  CalendarIcon,
  ChartSquareBarIcon,
  ClockIcon,
  FilmIcon,
  FlagIcon,
  FolderOpenIcon,
  HomeIcon,
  IdentificationIcon,
  LibraryIcon,
  NewspaperIcon,
  MenuIcon,
  MicrophoneIcon,
  MusicNoteIcon,
  PresentationChartBarIcon,
  PrinterIcon,
  SelectorIcon,
  SparklesIcon,
  SpeakerphoneIcon,
  StatusOnlineIcon,
  TerminalIcon,
  TranslateIcon,
  ScissorsIcon,
  UserGroupIcon,
  VideoCameraIcon,
  XIcon,
  UsersIcon,
  UserIcon
} from '@heroicons/react/solid'

import MainNavSidebarItem from '@/Layout/MainNavSidebarItem'

function MenuLink(props) {
  const { to, children, ...rest } = props;
  const classes = 'text-clcnavy border-transparent hover:text-clcnavy-light dark:text-clcnavy-light dark:hover:text-clcnavy dark:bg-gray-700 hover:bg-gray-200 group flex items-center border-l-4 px-3 py-2 text-sm font-medium rounded-r-md'
  return <a href={to} className={classes} {...rest}>{children}</a>
}

const MainNavMenuItem = (props) => {
  const { selected, url, name } = props
  return (
      <Menu.Item>
        <a href={url} className={classNames(
          selected ? 'bg-gray-100 text-gray-900 dark:bg-gray-800' : 'text-gray-700 dark:bg-gray-700',
          'block px-4 py-2 text-sm dark:text-white '
        )}
        > {name} <span className="sr-only">Open {name}</span> </a>
      </Menu.Item>
  )
}

export default function Header (props) {
  const [currentUser, setCurrentUser] = useGlobalState('currentUser')
  const [h1, setH1] = useState(props.h1)

  useEffect(() => {
    if (!currentUser?.id) { setCurrentUser(props.currentUser) }
  }, [])

  if (!currentUser?.id) { return null }

  return <span className='border-b-2 border-clcpurple'>
    <Disclosure as="nav">
      {({ open }) => (
        <>
          <div className="relative z-10 flex-shrink-0 flex h-16">
            <div className="flex items-center lg:hidden">
              <Disclosure.Button className="bg-white dark:bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon className="block h-6 w-6" aria-hidden="true" />
                ) : (
                  <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                )}
              </Disclosure.Button>
            </div>

            <div className="flex-1 px-1 items-center flex justify-between dark:text-white dark:bg-gray-800">
              <h1 className="ml-16 text-2xl relative h-8 w-auto align-middle mb-0">{h1} </h1>
              <div className="w-auto ml-4 flex items-center lg:ml-6 px-3 dark:bg-gray-800">
                <a href="/courses" className="hidden md:block whitespace-nowrap mr-5 py-2 text-sm">
                  <span>Courses</span>
                </a>
                <a href="/teach_with_us" className="hidden md:block whitespace-nowrap mr-5 py-2 text-sm">
                  <span>Teach With Us</span>
                </a>

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative md:hidden">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">
                          <span className="sr-only" hidden>Open user menu</span>
                          <div className="flex-shrink-0">
                            <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500">
                              <span className="leading-none text-white">
                                <div className='flex flex-col justify-center items-center'>
                                  <span className='capitalize'>{currentUser?.email[0]}</span>
                                </div>
                              </span>
                            </span>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-white ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                        >
                          <div>
                            <Menu.Item>
                              <ThemeToggle />
                            </Menu.Item>
                            <Menu.Item as={MenuLink} to='/dashboard'> Dashboard </Menu.Item>
                            <Menu.Item as={MenuLink} to='/settings'> Settings </Menu.Item>
                            <Menu.Item as={MenuLink} to='/subscriptions'> Subscriptions </Menu.Item>
                            <Menu.Item as={MenuLink} to='/chat'> Discord </Menu.Item>
                          </div>
                          <div>
                            <Menu.Item as={MenuLink} to='/faqs'> Support </Menu.Item>
                            <Menu.Item as={MenuLink} to='/logout'> Logout </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>

                {/* User account dropdown */}
                <Menu as="div" className="relative inline-block text-left hidden md:block mt-6 md:mt-0">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="group w-full bg-gray-100 dark:bg-gray-700 dark:hover:bg-gray-800 rounded-md px-2 md:px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-cccpurple">
                          <span className="flex w-full justify-between items-center">
                            <span className="flex min-w-0 items-center justify-between space-x-3">

                              <div className="flex-shrink-0">
                                <span className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500">
                                  <span className="leading-none text-white">
                                    <div className='flex flex-col justify-center items-center'>
                                      <span className='capitalize'>{currentUser?.email[0]}</span>
                                    </div>
                                  </span>
                                </span>
                              </div>
                              <span className="flex-1 flex flex-col min-w-0 w-20 md:w-32 overflow-hidden">
                                <span className="text-gray-900 dark:text-white text-sm font-medium truncate">{currentUser?.displayName}</span>
                                <span className="text-xs truncate">{currentUser?.access === 'member' ? <span className='text-clcpurple'>Member</span> : <span className='text-clcpurple'>Free Plan</span>}</span>
                              </span>
                            </span>
                            <SelectorIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              aria-hidden="true"
                            />
                          </span>
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="z-10 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-opacity-5 focus:outline-none"
                        >
                          <div>
                            <Menu.Item>
                              <ThemeToggle />
                            </Menu.Item>

                            <Menu.Item as={MenuLink} to='/dashboard'> Dashboard </Menu.Item>
                            <Menu.Item as={MenuLink} to='/settings'> Settings </Menu.Item>
                            <Menu.Item as={MenuLink} to='/subscriptions'> Subscriptions </Menu.Item>
                            <Menu.Item as={MenuLink} to='/chat'> Discord </Menu.Item>
                          </div>
                          <div>
                            <Menu.Item as={MenuLink} to='/faqs'> Support </Menu.Item>
                            <Menu.Item as={MenuLink} to='/logout'> Logout </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
          <Disclosure.Panel className="lg:hidden">
            {({ close }) => (
              <div className="flex-1 relative overflow-y-auto h-screen focus:outline-none dark:bg-gray-700 bg-gray-100">
                <div className="pt-2 pb-3 space-y-1">
                  <a href="/dashboard" className="border-transparent text-beecarbon hover:text-beegreen-alt dark:text-beegreen hover:bg-gray-200 group flex items-center border-l-4 px-3 py-2 font-medium rounded-r-md">
                    Dashboard
                  </a>
                  <div className='font-semibold uppercase px-3 dark:text-gray-100'>Learn</div>
                  <a href="/mycourses" className="border-transparent text-beecarbon hover:text-beegreen-alt dark:text-beegreen hover:bg-gray-200 group flex items-center border-l-4 px-3 py-2 font-medium rounded-r-md">
                    My Courses
                  </a>
                  <a href="/myevents" className="border-transparent text-beecarbon hover:text-beegreen-alt dark:text-beegreen hover:bg-gray-200 group flex items-center border-l-4 px-3 py-2 font-medium rounded-r-md">
                    My Events
                  </a>
                  <a href="/select_course" className="border-transparent text-beecarbon hover:text-beegreen-alt dark:text-beegreen hover:bg-gray-200 group flex items-center border-l-4 px-3 py-2 font-medium rounded-r-md">
                    Enrolling Courses
                  </a>
                  <a href="/events" className="border-transparent text-beecarbon hover:text-beegreen-alt dark:text-beegreen hover:bg-gray-200 group flex items-center border-l-4 px-3 py-2 font-medium rounded-r-md">
                    Events & Workshops
                  </a>
                  <div className='font-semibold uppercase px-3 dark:text-gray-100'>Teach</div>
                  <a href="/teachers/schools" className="border-transparent text-beecarbon hover:text-beegreen-alt dark:text-beegreen hover:bg-gray-200 group flex items-center border-l-4 px-3 py-2 font-medium rounded-r-md">
                    Manage Schools
                  </a>
                  <a href="/teachers/courses" className="border-transparent text-beecarbon hover:text-beegreen-alt dark:text-beegreen hover:bg-gray-200 group flex items-center border-l-4 px-3 py-2 font-medium rounded-r-md">
                    Manage Courses
                  </a>
                </div>
              </div>
            )}
          </Disclosure.Panel>
        </>
      )}

    </Disclosure>
  </span>
}

MainNavMenuItem.propTypes = {
  selected: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}
